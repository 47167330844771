<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title v-if="dataItem.WddStatus == 'Y'"
          >Add Route Assignment(Approved)</v-toolbar-title
        >
        <v-toolbar-title v-else>View Route Assignment</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text @click="goBack">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor :initial="dataItem" :docCreate="docCreate" @data="save"></editor>
        <!-- end -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["vehicle"]),
  },
  data: () => ({
    dataItem: {},
    docCreate: false,
  }),
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true,
    },
  },
  methods: {
    getData(id) {
      const self = this;
      this.$store
        .dispatch(
          "get",
          `/route_assignments/${id}?DocStatus=${this.$route.query.DocStatus}`
        )
        .then((res) => {
          self.dataItem = res.ResponseData;
          if (self.dataItem.WddStatus == "Y") {
            self.dataItem.draftEntry = self.dataItem.id;
            self.dataItem.addDraft = true;
          } else {
            self.dataItem.addDraft = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      if (this.dataItem.WddStatus == "Y") {
        this.saveDocument(data);
      } else {
        this.updateDoc(data);
      }
      this.$store.commit("loader", false);
    },
    updateDoc(data) {
      console.log("UPDATING.....");
      const url = `/route_assignments/${data.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    saveDocument(data) {
      console.log("SAVING DOC....");
      console.log(data);
      this.docData = data;
      let URL = "/route_assignments";
      const ENDPOINT = URL;
      const self = this;
      this.$store
        .dispatch("post", { data, url: ENDPOINT })
        .then((res) => {
          self.$store.commit("loader", false);
          if (res.ResultCode == 1201) {
            self.RemarksDialog = true;
          }
        })
        .catch((err) => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
